import {useEffect, useRef, useState} from "react";
import {gsap} from "gsap";
import classNames from "classnames";
import {CenterBottomArrow, CenterTopArrow, RightBottomArrow} from "./ArrowIcon";

interface Props {
  currentIdx: number;
}

export default function Section1Background({currentIdx}: Props) {
  const mainCircleRef = useRef(null);
  const mainCircleBoundRef = useRef(null);
  const gradientCircleBoundRef = useRef(null);
  const subCircleRef = useRef(null);
  const gradientCircleRef = useRef(null);
  const gradientBoundRefs = useRef<SVGStopElement[]>([]);
  const subCircleBoundRef = useRef(null);
  const mainTextRefs = useRef<HTMLDivElement[]>([]);
  const subTextRefs = useRef<HTMLDivElement[]>([]);
  const [prevIdx, setPrevIdx] = useState(0);
  const [angle, setAngle] = useState(0);

  useEffect(() => {
    gsap.to([mainCircleRef.current], {
      duration: 1,
      rotation: angle,
      overwrite: "auto",
    });
    gsap.to([subCircleRef.current, gradientCircleRef.current], {
      duration: 1,
      rotation: -angle,
      overwrite: "auto",
    });
    gsap.to(mainTextRefs.current, {
      duration: 1,
      rotation: -angle,
      overwrite: "auto",
    });
    gsap.to(subTextRefs.current, {
      duration: 1,
      rotation: angle,
      overwrite: "auto",
    });
  }, [angle]);

  const rootCls = classNames({
    "h-[70vh] tablet:h-auto aspect-[2/1.22] tablet:min-w-[1800px] tablet:w-full tablet:aspect-[2/1.22] relative tablet:text-[11px] text-[13px] rotate-[70deg] tablet:rotate-0": true,
    "text-gray": currentIdx === 0 || currentIdx === 4,
    "text-white": currentIdx === 1 || currentIdx === 2,
    "text-dark-gray": currentIdx === 3,
  });

  const commonInnerCircleColorCls = classNames({
    "aspect-square rounded-full block": true,
    "border-gray": currentIdx === 0 || currentIdx === 4,
    "border-dark-gray": currentIdx === 3,
    "border-white": currentIdx === 1 || currentIdx === 2,
  });

  function getArrowColor() {
    if (currentIdx === 0 || currentIdx === 1 || currentIdx === 4) {
      return "#EFEFEF";
    } else if (currentIdx === 3) {
      return "#4D4E53";
    } else {
      return "white";
    }
  }

  useEffect(() => {
    setPrevIdx(prev => {
      if (prev !== currentIdx) {
        setAngle(prevAngle => prev < currentIdx ? prevAngle - 15 : prevAngle + 15);
      }
      return currentIdx;
    });

    gsap.to(mainCircleRef.current, {
      duration: 1,
      backgroundColor: currentIdx === 3
        ? "rgba(77,78,83,0.6)"
        : currentIdx === 2
          ? "rgba(255,255,255,0.2)"
          : "rgba(239,239,239,0.6)"
    });
    gsap.to([mainCircleBoundRef.current, subCircleBoundRef.current], {
      duration: 1,
      borderColor: currentIdx === 3 ? "#4D4E53" : currentIdx === 2 ? "rgba(255,255,255,0.5)" : "#EFEFEF",
    });
    gsap.to(gradientBoundRefs.current, {
      duration: 1,
      stopColor: currentIdx === 3 ? "#4D4E53" : currentIdx === 2 ? "rgba(255,255,255,0.5)" : "#EFEFEF",
    });
  }, [currentIdx]);
  return (
    <div className={rootCls}>
      {/* Center circle */}
      <div className={`absolute left-[34%] bottom-[16%] w-[29%] aspect-square rounded-full border block flex justify-center items-center`}
           ref={mainCircleBoundRef}>
        <div className="absolute left-[44%] -top-[4%] opacity-50 hidden tablet:block">
          UNDERSTAND
        </div>
        <div className="absolute top-[8%] left-[18.7%] tablet:left-[20%]">
          {/*<CenterTopArrow color={getArrowColor()}/>*/}
        </div>
        <div className="absolute bottom-[18%] right-[7.5%] tablet:right-[8.5%]">
          {/*<CenterBottomArrow color={getArrowColor()}/>*/}
          <div className="absolute -left-2 -bottom-2 opacity-50 hidden tablet:block">FEEDBACK</div>
        </div>
        <div className="absolute top-[31%] left-[0%] w-[5%] aspect-square opacity-50 z-10">
          <div className="w-full rounded-full aspect-square bg-white opacity-20"></div>
          <div className="absolute rounded-full w-[70%] left-[15%] top-[15%] aspect-square bg-gray opacity-40"></div>
          <div className="absolute rounded-full w-[50%] left-[25%] top-[25%] aspect-square bg-gray opacity-80"></div>
        </div>
        <div className="absolute top-[22%] right-[4.5%] w-[5%] aspect-square opacity-50 z-10">
          <div className="w-full rounded-full aspect-square bg-white opacity-20"></div>
          <div className="absolute rounded-full w-[70%] left-[15%] top-[15%] aspect-square bg-gray opacity-40"></div>
          <div className="absolute rounded-full w-[50%] left-[25%] top-[25%] aspect-square bg-gray opacity-80"></div>
          <div className="absolute -right-10 top-1 hidden tablet:block">THINK</div>
        </div>
        <div className="relative w-[92%] aspect-square rounded-full block opacity-50"
             ref={mainCircleRef}>
          <div className={`absolute w-[18%] right-[15%] bottom-[10%] border border-dashed ${commonInnerCircleColorCls}`}></div>
          <div className={`absolute w-[35%] bottom-[2%] left-[22%] border border-dashed ${commonInnerCircleColorCls}`}>
            <div className={`absolute w-[50%] left-0 top-[33%] border border-dashed ${commonInnerCircleColorCls}`}></div>
            <div className="relative w-full h-full hidden tablet:block" ref={(el) => {
              if (el) {
                mainTextRefs.current[0] = el
              }
            }}>
              <div className="absolute left-[40%] top-[7%]">
                EXPLORE
              </div>
            </div>
          </div>
          <div className={`absolute w-[20%] -top-[18.5%] right-[12%] border border-dashed ${commonInnerCircleColorCls}`}>
            <div className="relative w-full h-full hidden tablet:block" ref={(el) => {
              if (el) {
                mainTextRefs.current[1] = el
              }
            }}>
              <div className="w-full h-full flex items-center justify-center">
                PIVOT
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*Right circle */}
      <div className={`absolute right-[11.5%] top-[14%] w-[29%] aspect-square rounded-full border block flex justify-center items-center`}
           ref={subCircleBoundRef}>
        <div className="absolute bottom-[6%] left-[21%]">
          {/*<RightBottomArrow color={getArrowColor()}/>*/}
          <div className="absolute -left-1 -bottom-7 opacity-50 hidden tablet:block">LEARN</div>
        </div>
        <div className="w-full h-full opacity-50" ref={subCircleRef}>
          <div className={`absolute w-[20%] top-[0%] right-[40%] border border-dashed ${commonInnerCircleColorCls}`}>
            <div className="relative w-full h-full hidden tablet:block" ref={(el) => {
              if (el) {
                subTextRefs.current[0] = el
              }
            }}>
              <div className="w-full h-full flex items-center justify-center">
                TEST
              </div>
            </div>
          </div>
          <div className={`absolute w-[20%] top-[14%] right-[10%] border border-dashed ${commonInnerCircleColorCls}`}>
            <div className="relative w-full h-full hidden tablet:block" ref={(el) => {
              if (el) {
                subTextRefs.current[1] = el
              }
            }}>
              <div className="w-full h-full flex items-center justify-center">
                TEST
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*Left circle */}
      <div className={`absolute left-[5.8%] top-[17%] w-[29%] aspect-square block flex justify-center items-center block`}
           ref={gradientCircleBoundRef}>
        <div className="block w-full h-full">
          <svg style={{aspectRatio: 0.55, float:"right", width: "90%", height: "100%"}} viewBox="0 0 574 321" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M573 321C573 144.269 436.971 1 269.172 1C163.382 1 47.551 54.5591 1 163" stroke="url(#paint0_linear_586_5102)"/>
            <defs>
              <linearGradient id="paint0_linear_586_5102" x1="573" y1="321" x2="1" y2="321" gradientUnits="userSpaceOnUse">
                <stop stopColor="white" ref={(el) => {
                  if (el) {
                    gradientBoundRefs.current[0] = el;
                  }
                }}/>
                <stop offset="1" stopColor="white" stopOpacity="0" ref={(el) => {
                  if (el) {
                    gradientBoundRefs.current[1] = el;
                  }
                }}/>
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="absolute right-0 w-[100%] h-full top-[22.5%] opacity-50" ref={gradientCircleRef}>
          <div className={`absolute w-[30%] top-[2%] right-[35%] border border-dashed ${commonInnerCircleColorCls}`}>
            <div className="relative w-full h-full hidden tablet:block" ref={(el) => {
              if (el) {
                subTextRefs.current[3] = el
              }
            }}>
              <div className="w-full h-full flex items-center justify-center">
                THING
              </div>
            </div>
          </div>
        </div>
        <div className="absolute left-[50%] top-[22%] opacity-50 hidden tablet:block">
          NEEDS
        </div>
      </div>
    </div>
  )
}
