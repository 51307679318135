interface Props {
  color: 'red' | 'black' | 'mixed' | 'mixed2' | 'gray' | 'white';
  size: 'small' | 'medium';
}

function getTopColor(color: Props['color']) {
  switch (color) {
    case 'red':
    case 'mixed':
    case 'mixed2':
      return '#EB3C34';
    case 'black':
      return '#2C2D2F';
    case 'gray':
      return '#D0D0D0';
  }
}

function getBottomColor(color: Props['color']) {
  switch (color) {
    case 'red':
      return '#EB3C34';
    case 'black':
    case 'mixed':
      return '#2C2D2F';
    case 'gray':
      return '#D0D0D0';
    case 'white':
    case 'mixed2':
      return '#FFFFFF';
  }
}
export default function ClipIcon({size, color}: Props) {
  return (
    <svg width={size === 'small' ? '20' : '40'}
         height={size === 'small' ? '20': '40'}
         viewBox={"0 0 40 40"} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.4454 13.6253L25.4901 8.58147C27.4129 6.65896 30.5354 6.65232 32.4501 8.56666C34.3648 10.481 34.3581 13.603 32.4353 15.5255L26.7765 21.1834C24.8537 23.1059 21.7312 23.1125 19.8165 21.1982C19.2123 20.594 18.2346 20.5961 17.6278 21.2028C17.021 21.8095 17.0189 22.787 17.6232 23.3911C20.7464 26.4992 25.8241 26.4884 28.9606 23.3524L34.6194 17.6946C37.7485 14.5659 37.7666 9.48174 34.6435 6.3591C31.5203 3.23647 26.4426 3.24727 23.3061 6.38322L16.4483 13.2399C16.3898 13.2984 16.3386 13.3641 16.2874 13.4299C17.6667 13.2008 19.082 13.2562 20.4382 13.618L20.4454 13.6253Z"
            fill={getTopColor(color)}/>
      <path d="M20.5549 27.3894L15.5102 32.4333C13.5874 34.3558 10.4649 34.3624 8.55019 32.4481C6.63553 30.5337 6.64216 27.4117 8.56499 25.4892L14.2238 19.8314C16.1466 17.9089 19.2691 17.9022 21.1838 19.8166C21.788 20.4207 22.7656 20.4186 23.3725 19.8119C23.9793 19.2052 23.9814 18.2277 23.3771 17.6236C20.2612 14.5082 15.1762 14.5118 12.0398 17.6477L6.38096 23.3056C3.25177 26.4488 3.24098 31.5257 6.35686 34.641C9.47273 37.7564 14.5577 37.7529 17.6942 34.6169L24.552 27.7602C24.6032 27.7091 24.6544 27.6433 24.7056 27.5776C23.3263 27.8066 21.9111 27.7513 20.5549 27.3894Z"
            fill={getBottomColor(color)}/>
    </svg>
  )
}
