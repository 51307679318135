import ClipIcon from "./ClipIcon";
import React, {useState} from "react";
interface Props {
  onBackToTop: () => void;
}
export default function Footer({onBackToTop}: Props) {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const textAreaRef = React.useRef<HTMLTextAreaElement|null>(null);
  const autoResizeTextarea = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = `auto`;
      const height = textAreaRef.current.value === "" ? 30 : Math.max(textAreaRef.current.scrollHeight, 30);
      textAreaRef.current.style.height = `${height}px`;
    }
  };

  const handleSendMail = async () => {
    if (name === "" || email === "" || message === "") {
      alert("모든 항목을 입력해주세요.");
      return;
    }
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("contents", message);

    fetch("/send.php", {
      method: "POST",
      body: formData,
    })
      .then(data => {
        alert("메일 전송이 완료되었습니다.");
      })
      .catch(err => {
        alert(`메일 전송에 실패했습니다. 다시 시도해주세요.`);
      });
  }

  return (
    <div className="w-full p-6 tablet:p-20 bg-black flex flex-col z-30">
      <div className="hidden tablet:flex w-full justify-between items-startd laptop:mt-40 tablet:mt-20">
        <ClipIcon color="mixed2" size="medium"/>
        <div className="flex items-end text-white">
          <div className="laptop:text-[32px] tablet:text-[24px] leading-loose">CONNECT TO</div>
          <div className="laptop:text-[180px] tablet:text-[100px] font-semibold leading-none">UXID.</div>
        </div>
      </div>
      <div className="tablet:hidden flex w-full justify-between items-startd mt-20">
        <ClipIcon color="mixed2" size="small"/>
        <div className="flex items-end text-white gap-x-2">
          <div className="text-[14px] leading-loose">CONNECT TO</div>
          <div className="text-[60px] font-semibold leading-none">UXID.</div>
        </div>
      </div>
      <div className="hidden tablet:block w-full bg-gray h-[1px] laptop:mt-40 tablet:mt-20 opacity-40"></div>
      <div className="w-full mt-16 flex flex-col tablet:flex-row justify-between text-white">
        <div className="w-full tablet:w-[40%] flex flex-col">
          <a href="https://map.naver.com/p/search/%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%20%EC%84%9C%EC%B4%88%EA%B5%AC%20%EC%96%91%EC%9E%AC%EB%8F%99%2084-2/address/14142300.9099216,4506375.1150313,%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%20%EC%84%9C%EC%B4%88%EA%B5%AC%20%EC%96%91%EC%9E%AC%EB%8F%99%2084-2,jibun?searchType=address&isCorrectAnswer=true&c=19.00,0,0,0,dh"
            target="_blank"
             rel="address">
            <img className="w-full" src={"/images/map.jpg"} alt={"map"}/>
          </a>
          <div className="flex flex-col mt-8 text-[13px] tablet:text-[15px]">
            <div className="font-bold">VISIT US</div>
            <div className="mt-5">서울특별시 서초구 양재동 84-2 3층</div>
            <div className="mt-4">06746 K-Park Building - Floor 3, <br/>218, Baumoe-ro, Seocho-gu, Seoul, Republic of Korea</div>
            <div className="font-bold mt-7">E-mail</div>
            <div className="mt-5">uxid_info@uxidstudio.com</div>
          </div>
        </div>
        <div className="flex flex-col w-full tablet:w-[50%] text-[14px] tablet:text-[18px] mt-20 tablet:mt-0">
          <div className="w-full break-keep">
            프로젝트를 의뢰하시거나, 팀에 합류하거나 혹은 단순 코멘트를 남겨주셔도 좋으니 무엇이든 물어보세요.<br/>
            유씨드는 언제나 고객님의 목소리에 귀를 기울입니다.
          </div>
          <div className="w-full bg-gray h-[1px] mt-12"></div>
          <input type="text"
                 value={name}
                 onChange={(e) => setName(e.target.value)}
                 placeholder={"Name"}
                 className="w-full bg-transparent h-20 outline-none text-dark-gray"/>
          <div className="w-full bg-gray h-[1px]"></div>
          <input type="text"
                 value={email}
                 onChange={(e) => setEmail(e.target.value)}
                 placeholder={"Email address"}
                 className="w-full bg-transparent h-20 outline-none text-dark-gray"/>
          <div className="w-full bg-gray h-[1px]"></div>
          <textarea value={message}
                    ref={textAreaRef}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder={"Tell us about your story"}
                    className="w-full bg-transparent h-[30px] mt-8 outline-none text-dark-gray"
                    onKeyDown={autoResizeTextarea}
                    onKeyUp={autoResizeTextarea}/>
          <div className="w-full bg-gray h-[1px] mt-8"></div>
          <div className="w-full flex tablet:justify-end mt-8">
            <button className="w-[107px] h-[43px] text-[13px] tablet:text-[15px] border rounded-full text-base border-white text-white"
                    onClick={handleSendMail}>
              Send
            </button>
          </div>
        </div>
      </div>
      <div className="hidden tablet:flex w-full justify-between mt-36 text-white text-[20px]">
        <div className="">©2023 UXID STUDIO. All right reserved</div>
        <button className="flex items-center gap-x-2" onClick={onBackToTop}>
          Back to top <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="8.53711" y1="2.42627" x2="8.53711" y2="16.9998" stroke="white" strokeWidth="1.5"/>
          <path d="M1.66742 8.60687L8.45166 1.82263L15.2359 8.60687" stroke="white" strokeWidth="1.5"/>
        </svg>
        </button>
      </div>
      <div className="tablet:hidden flex flex-col w-full items-center justify-center mt-32 text-white text-[16px]">
        <button className="flex items-center gap-x-2" onClick={onBackToTop}>
          Back to top <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="8.53711" y1="2.42627" x2="8.53711" y2="16.9998" stroke="white" strokeWidth="1.5"/>
          <path d="M1.66742 8.60687L8.45166 1.82263L15.2359 8.60687" stroke="white" strokeWidth="1.5"/>
        </svg>
        </button>
        <div className="mt-10">©2023 UXID STUDIO. All right reserved</div>
      </div>
    </div>
  )
}
