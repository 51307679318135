export const CenterTopArrow = ({color}: {color: string}) => {
  return (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.5" d="M12.9265 16.5874L1.49831 12.7932L3.92651 0.998945" stroke={color}/>
    </svg>
  )
}

export const CenterBottomArrow = ({color}: {color: string}) => {
  return (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.5" d="M0.998779 3.92792L12.793 1.49971L16.5872 12.9279" stroke={color}/>
    </svg>
  )
}

export const RightBottomArrow = ({color}: {color: string}) => {
  return (
    <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.5" d="M3.92651 16.5879L1.49831 4.79366L12.9265 0.999434" stroke={color}/>
    </svg>
  )
}

export const LeftTopArrow = ({color}: {color: string}) => {
  return (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.5" d="M16.5898 1L12.7956 12.4282L1.00139 10" stroke={color}/>
    </svg>
  )
}

export const BackToTopArrow = ({color}: {color: "light"|"dark"}) => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_262_495)">
        <circle cx="24" cy="24" r="16" fill={color === "light" ? "white" : "#4D4E53"}/>
      </g>
      <path d="M24 17V32M24 17L18 23.5993M24 17L30 23.5993" stroke={color === "dark" ? "white" : "#4D4E53"} strokeWidth="1.5"/>
      <defs>
        <filter id="filter0_d_262_495" x="0" y="0" width="52" height="52" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dx="2" dy="2"/>
          <feGaussianBlur stdDeviation="5"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_262_495"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_262_495" result="shape"/>
        </filter>
      </defs>
    </svg>

  )
}
