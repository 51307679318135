import {useEffect, useState} from "react";
import TextTransition, {presets} from 'react-text-transition';
import {LoadingTexts} from "../common/constants";
interface Props {
  onComplete: () => void;
}

export default function Loading({onComplete}: Props) {
  const [index, setIndex] = useState(0);
  const [timeoutMs, setTimeoutMs] = useState(500);
  const [finished, setFinished] = useState(false);
  const updateIndex = () => {
    setIndex((index) => {
      if (index === LoadingTexts.length - 1) {
        onComplete();
        setFinished(true);
        return index;
      }
      return index + 1;
    });
    setTimeoutMs(prev => prev - 50);
  }
  useEffect(() => {
    if (finished || index == 0) {
      return;
    }
    setTimeout(() => {
      updateIndex();
    }, timeoutMs);
  }, [timeoutMs]);

  useEffect(() => {
    setTimeout(() => {
      updateIndex();
    }, timeoutMs);
  }, []);

  return (
    <div className="flex w-full items-center justify-center h-screen text-[40px] font-normal bg-white">
      <div className="hidden tablet:flex w-[550px] ml-32 overflow-hidden relative">
        WE CONNECT <TextTransition springConfig={presets.stiff} translateValue="50%" className="ml-2 text-red">{LoadingTexts[index]}</TextTransition>
      </div>
      <div className="flex flex-col items-center w-full tablet:hidden overflow-hidden relative">
        WE CONNECT
        <TextTransition springConfig={presets.stiff} translateValue="35%" className="mt-2 ml-2 text-red flex w-full justify-center">{LoadingTexts[index]}</TextTransition>
      </div>
    </div>
  );
}
