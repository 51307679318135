import SpriteImage from "./SpriteImage";
import ClipIcon from "./ClipIcon";
import {useEffect, useRef} from "react";
import {gsap} from "gsap";

export default function Section2() {
  const arcRefs = useRef<HTMLDivElement[]>([]);
  const animate = () => {
    const tl = gsap.timeline();
    arcRefs.current.forEach((el, idx) => {
      tl.to(el, {
        duration: 1,
        opacity: 1,
      });
    });

    tl.to(arcRefs.current, {
      delay: 3,
      duration: 1,
      opacity: 0,
      onComplete: () => {
        animate();
      }
    });
  }

  useEffect(() => {
    animate();
  }, []);
  return (
    <div className="w-full h-auto flex flex-col items-center text-black">
      <div className="flex flex-col w-full p-6 tablet:p-20 bg-black text-white">
        <div className="w-full flex flex-col mt-16">
          <ClipIcon color="red" size="small"/>
          <div className="mt-4 text-[13px] tablet:text-[15px] flex flex-col">
            <div>Connect</div>
            <div>User.</div>
          </div>
        </div>
        <div className="flex w-full flex-col tablet:flex-row mt-[60px] tablet:mt-[120px] gap-y-10 tablet:gap-y-0">
          <div className="flex flex-col w-full tablet:w-1/3">
            <SpriteImage imageUris={["/images/consulting1.png", "/images/consulting2.png", "/images/consulting3.png", "/images/consulting4.png"]}/>
            <div className="flex mt-[30px] tablet:mt-[50px]">
              <div className="text-[13px] laptop:text-[15px] text-red mt-1 tablet:mt-2">1</div>
              <div className="text-[24px] laptop:text-[32px] ml-2">Consulting.</div>
            </div>
            <div className="mt-7 tablet:mt-[30px] w-full tablet:w-[70%] text-[14px] tablet:text-[16px]">
              충분한 소통을 통해 고객의 요구 사항, 욕구 및 경험 등을 이해함을 바탕으로 사용자 중심의 관점에서 문제점을 파악하고 기업의 경쟁력과 고객 만족도를 높이는 전략 개발 컨설팅을 제안합니다.
            </div>
          </div>
          <div className="flex flex-col w-full tablet:w-1/3 tablet:mt-0">
            <SpriteImage imageUris={["/images/research1.png", "/images/research2.png", "/images/research3.png", "/images/research4.png"]}/>
            <div className="flex mt-[30px] tablet:mt-[50px]">
              <div className="text-[13px] laptop:text-[15px] text-red mt-1 tablet:mt-2">2</div>
              <div className="text-[24px] laptop:text-[32px] ml-2">Research.</div>
            </div>
            <div className="mt-7 tablet:mt-[30px] w-full tablet:w-[70%] text-[14px] tablet:text-[16px]">
              사용자 연구 및 설문조사, 행동 분석, 경쟁 기업 분석, 시장 조사 등 정량적 분석을 통해 사용자들이 사용하는 방식과 요구사항 및 욕구 등을 파악하여 사용자 중심의 제품 또는 서비스를 만들기 위한 기반을 형성합니다.
            </div>
          </div>
          <div className="flex flex-col w-full tablet:w-1/3">
            <SpriteImage imageUris={["/images/design1.png", "/images/design2.png", "/images/design3.png", "/images/design4.png"]}/>
            <div className="flex mt-[30px] tablet:mt-[50px]">
              <div className="text-[13px] laptop:text-[15px] text-red mt-1 tablet:mt-2">3</div>
              <div className="text-[24px] laptop:text-[32px] ml-2">Defining & Design.</div>
            </div>
            <div className="mt-[30px] w-full">
              사용자 중심 기반의 디자인을 설계하면서 반복적인 피드백을 통해 개선하고, 사용자들이 직면하는 문제점을 해결합니다. 다양한 환경에서 마주하는 삶과 경험의 질을 높이는 최적의 디자인으로 정의합니다.
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full p-6 tablet:p-20 bg-black text-white h-auto">
        <div className="mt-20 w-full flex flex-col">
          <ClipIcon color="red" size="small"/>
          <div className="mt-4 text-[13px] tablet:text-[15px] flex flex-col">
            <div>Connect</div>
            <div>Flow.</div>
          </div>
        </div>
        <div className="flex tablet:flex-col w-full tablet:h-[50vw] justify-end items-center pb-10 tablet:pb-[10%] mt-12 tablet:mt-0">
          <div className="hidden tablet:block relative w-[85%]">
            <div className="flex w-full absolute bottom-[40%] opacity-0"
                 ref={el => {
                   if (!el) {
                     return;
                   }
                   arcRefs.current[0] = el;
                 }}>
              <img src="/images/arc1.png" alt="line" className="absolute bottom-0 right-[1%] w-[78.93206484%] object-contain"/>
            </div>
            <div className="flex w-full absolute bottom-[40%] opacity-0"
                 ref={el => {
                   if (!el) {
                     return;
                   }
                   arcRefs.current[1] = el;
                 }}>
              <img src="/images/arc2.png" alt="line" className="absolute bottom-0 right-[1%] w-[59.2670752%] object-contain"/>
              <img src="/images/arc3.png" alt="line" className="absolute bottom-0 right-[40.6%] w-[19.7%] object-contain"/>
            </div>
            <div className="flex w-full absolute bottom-[40%] opacity-0"
                 ref={el => {
                   if (!el) {
                     return;
                   }
                   arcRefs.current[2] = el;
                 }}>
              <div className="absolute bottom-0 right-[20.8%] w-[39.4%]">
                <div className="text-dark-gray absolute w-full -top-8 flex justify-center">Lean UX Research Cycle</div>
                <img src="/images/arc-red1.png" alt="line" className="w-full h-full object-contain"/>
                <img src="/images/arc-red2.png" alt="line" className="absolute -bottom-[100%] w-full h-full object-contain"/>
              </div>
            </div>
            <div className="flex w-full absolute bottom-[38%]">
              <img src="/images/line.png" alt="line" className="w-full object-contain"/>
            </div>
          </div>
          <div className="w-7 h-full tablet:hidden flex items-start mt-4">
            <img src="/images/line2.png" alt="line" className="h-[91.2%] object-cover"/>
          </div>
          <div className="w-full flex flex-col tablet:flex-row tablet:mt-8 gap-y-[50px] ml-4 tablet:ml-0">
            <div className="w-full tablet:w-1/6 flex flex-col tablet:items-center">
              <div className="text-[20px] tablet:text-[16px] laptop:text-[20px] font-bold">Understanding</div>
              <div className="mt-5 text-[16px] tablet:text-[14px] laptop:text-[16px] flex flex-col tablet:items-center tablet:w-[80%] tablet:text-center break-keep">
                <div>제품 및 서비스의 목표를 이해하고 <br className="tablet:hidden"/> 사용자 관점의 요구사항을 점검합니다</div>
              </div>
            </div>
            <div className="w-full tablet:w-1/6 flex flex-col tablet:items-center">
              <div className="text-[20px] tablet:text-[16px] laptop:text-[20px] font-bold">Planning</div>
              <div className="mt-5 text-[16px] tablet:text-[14px] laptop:text-[16px] flex flex-col tablet:items-center tablet:w-[80%] tablet:text-center break-keep">
                <div>가장 효율적이고 효과적인 <br className="tablet:hidden"/> 리서치 계획을 수립 및 제안합니다</div>
              </div>
            </div>
            <div className="w-full tablet:w-1/6 flex flex-col tablet:items-center">
              <div className="text-[20px] tablet:text-[16px] laptop:text-[20px] font-bold tablet:text-center">User Research</div>
              <div className="mt-5 text-[16px] tablet:text-[14px] laptop:text-[16px] flex flex-col tablet:items-center tablet:w-[80%] tablet:text-center break-keep">
                <div>다양한 방법론을 활용하여 <br className="tablet:hidden"/> 유저의 행동을 관찰하고 <br className="tablet:hidden"/> 생각을 이해하며 반응을 수집합니다</div>
              </div>
            </div>
            <div className="w-full tablet:w-1/6 flex flex-col tablet:items-center">
              <div className="text-[20px] tablet:text-[16px] laptop:text-[20px] font-bold">Discovery</div>
              <div className="mt-5 text-[16px] tablet:text-[14px] laptop:text-[16px] flex flex-col tablet:items-center tablet:w-[80%] tablet:text-center break-keep">
                <div>수집된 결과를 분석하여 <br className="tablet:hidden"/> 인사이트를 발굴하고, <br className="tablet:hidden"/> 아이데이션을 통해 구체화합니다</div>
              </div>
            </div>
            <div className="w-full tablet:w-1/6 flex flex-col tablet:items-center">
              <div className="text-[20px] tablet:text-[16px] laptop:text-[20px] font-bold tablet:text-center">Design & Prototype</div>
              <div className="mt-5 text-[16px] tablet:text-[14px] laptop:text-[16px] flex flex-col tablet:items-center tablet:w-[80%] tablet:text-center break-keep">
                <div>구체화된 아이디어를 <br className="tablet:hidden"/> 최적의 디자인으로 구현합니다</div>
              </div>
            </div>
            <div className="w-full tablet:w-1/6 flex flex-col tablet:items-center">
              <div className="text-[20px] tablet:text-[16px] laptop:text-[20px] font-bold">Finalization</div>
              <div className="mt-5 text-[16px] tablet:text-[14px] laptop:text-[16px] flex flex-col tablet:items-center tablet:w-[80%] tablet:text-center break-keep">
                <div>UX리서치를 통해 최적화된 <br className="tablet:hidden"/> UI 전략을 제안 및 정의합니다</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
