interface Props {
  name: string;
  imageUri: string;
}

export default function PartnerInfo({name, imageUri}: Props) {
  return (
    <div className="w-auto h-full flex flex-shrink-0 items-center h-full">
      <div className="text-black text-[20px] laptop:text-[40px]">{name}</div>
      <img src={imageUri} alt={name} className="ml-3 h-full aspect-[6/4]"/>
    </div>
  )
}
