export const LoadingTexts = ['NEED', 'THINK', 'REASON', 'PEOPLE', 'LOGIC', 'UNDERSTAND', 'EXPLORE'];
export const Section1Data = [
  {title: "User", bgColor: "#ffffff", color: "#2C2D2F", logoColor: "#EB3C34"},
  {title: "eXperience", bgColor: "#D0D0D0", color: "#4D4E53", logoColor: "#EB3C34"},
  {title: "Interface", bgColor: "#EB3C34", color: "#FFFFFF", logoColor: "#ADADAD"},
  {title: "Design", bgColor: "#2C2D2F", color: "#FFFFFF", logoColor: "#EB3C34"},
  {title: "UXID", bgColor: "#ffffff", color: "#000000", logoColor: "#EB3C34"},
];

export const Section3Data = [
  {
    title: "Finance",
    indexString: "01",
    description: "고객 중심적 서비스 제공과 함께 안정성과 신뢰성을 보장할 수 있도록 금융환경 바탕의 전략을 기반으로 간편한 기능과 보안적인 요소를 고려한 금융시스템 설계를 제공합니다.",
    imageNames: [
      "finance1.jpg",
      "finance2.jpg",
      "finance3.jpg",
    ],
    projects: [
      "마이데이터 서비스 UIUX",
      "베트남 P2P서비스 UIUX",
      "디지털채널 경쟁력 조사",
      "가맹점 마케팅 플랫폼 UIUX",
      "MZ, 시니어, 금융서비스 이용자 등 사용자 조사",
    ],
    direction: "left",
  },
  {
    title: "Mobility",
    indexString: "02",
    description: "이동적인 환경에 최적화된 사용자 중심 분석을 수행하며 일상생활 속 사용되는 모바일 및 플랫폼의 평가 및 최적화를 위한 프로토타입 설계 등을 수행합니다.",
    imageNames: [
      "mobility1.jpg",
      "mobility2.jpg",
      "mobility3.jpg",
    ],
    projects: [
      "아차키 사용자 조사",
      "클러스터 개인화 모바일 앱 사용성 평가",
      "하드키 축소를 위한 사용자 평가",
      "고급형 및 보급형 AVNT 컨셉 UIUX",
      "신규조작계 전용 앱 개발 및 사용성 평가",
    ],
    direction: "right",
  },
  {
    title: "Service",
    indexString: "03",
    description: "IT 서비스 개발 프로젝트를 수행하기 위해 소통을 통해 고객사의 요구사항을 이해하고, 필요한 기술을 연구하여, 유연하고 효율적인 컨설팅 전략을 도입합니다.",
    imageNames: [
      "service1.jpg",
      "service2.jpg",
      "service3.jpg",
    ],
    projects: [
      "NFT 포털서비스 UIUX",
      "전기차 충전소 모니터링 대시보드 & 앱 UIUX",
      "스마트캠퍼스 리빙랩허브 플랫폼 개발",
      "스마트 리클라이너 서비스 UIUX",
      "첨단융합공연 지식기반 플랫폼 서비스 UIUX",
    ],
    direction: "left",
  },
  {
    title: "System",
    indexString: "04",
    description: "대형 제조업체 생산 관리 시스템 구축 프로젝트를 수행하며 과정에서 발생하는 다양한 데이터를 분석의 최적화, 계획 ∙ 관리 체계의 IT시스템을 구축합니다.",
    imageNames: [
      "system1.jpg",
      "system2.jpg",
      "system3.jpg",
    ],
    projects: [
      "대학병원 연구검색 시스템 UIUX",
      "프로세스 마이닝 솔루션 UIUX",
      "SW품질 검증 솔루션 UIUX 컨설팅",
      "비대면 감성지능 모니터링 시스템 UIUX",
      "재정, 물류, 학교 등 차세대 관리시스템 UIUX",
    ],
    direction: "right",
  },
  {
    title: "Public",
    indexString: "05",
    description: "사용자 중심의 디자인과 철저한 분석을 통해 사회에 대한 실질적인 성과를 도출하는 다양한 UXUI 전략 위주의 대중적 프로젝트를 수행합니다.",
    imageNames: [
      "public1.jpg",
      "public2.jpg",
      "public3.jpg",
    ],
    projects: [
      "청와대사랑채 키네틱 포토존 UIUX",
      "한국장학재단 출근부 UIUX",
      "개방∙공유∙참여형 도시서비스 운영 플랫폼 개발",
      "국립국악원 IT&UX융합 디지털서비스 시나리오 발굴",
      "‘국민DR’ 브랜딩 & 로고디자인",
    ],
    direction: "left",
  }
];

export const Partners1 = [
  {
    partner: "Samsung Card",
    logoName: "samsung_card.png",
  },
  {
    partner: "Hyundai",
    logoName: "hyundai.png",
  },
  {
    partner: "Socar",
    logoName: "socar.png",
  },
  {
    partner: "Shinhan Card",
    logoName: "shin_card.png",
  },
  {
    partner: "Riot Games",
    logoName: "riot.png",
  },
  {
    partner: "POSCO",
    logoName: "posco.png",
  },
  {
    partner: "Hyundai Mobis",
    logoName: "mobis.png",
  },
  {
    partner: "SK Broadband",
    logoName: "skbroad.png",
  },
  {
    partner: "Tuneit",
    logoName: "tuneit.png",
  },
  {
    partner: "OK Savings Bank",
    logoName: "okbank.png",
  },
  {
    partner: "National Gugak Center",
    logoName: "ngc.png",
  },
  {
    partner: "KITECH",
    logoName: "kitech.png",
  },
  {
    partner: "Korea Power Exchange",
    logoName: "kpx.png",
  }
]

export const Partners2 = [
  {
    partner: "POSTECH",
    logoName: "postech.png",
  },
  {
    partner: "DGB Daegu Bank",
    logoName: "dgb.png",
  },
  {
    partner: "KAIST",
    logoName: "kaist.png",
  },
  {
    partner: "NIPA",
    logoName: "nipa.png",
  },
  {
    partner: "Encored Technologies",
    logoName: "encored.png",
  },
  {
    partner: "Cheongwadae Sarangchae",
    logoName: "cheongwadae.png",
  },
  {
    partner: "KIDP",
    logoName: "kidp.png",
  },
  {
    partner: "Hanwha Systems",
    logoName: "hanhwa.png",
  },
  {
    partner: "Hyosung TNS",
    logoName: "hyosung.png",
  },
  {
    partner: "Pmgrow",
    logoName: "pmgrow.png",
  },
  {
    partner: "University of Seoul",
    logoName: "seoul_univ.png",
  },
  {
    partner: "Puzzle Data",
    logoName: "puzzle.png",
  },
]
