import ClipIcon from "./ClipIcon";
import React from "react";
import Marquee from "react-fast-marquee";
import {Partners1, Partners2} from "../common/constants";
import PartnerInfo from "./PartnerInfo";
export default function Section4() {
  return (
    <div className="w-full pb-20 tablet:pb-40">
      <div className="w-full hidden tablet:block">
        <Marquee className="mt-20" style={{width: "100vw"}} speed={160}>
          {Partners1.map((item, idx) => (
            <div key={idx} className="h-9 tablet:h-[60px] ml-4">
              <PartnerInfo name={item.partner} imageUri={`images/partners/${item.logoName}`}/>
            </div>
          ))}
        </Marquee>
        <Marquee className="mt-8 tablet:mt-20" style={{width: "100vw"}} speed={220}>
          {Partners2.map((item, idx) => (
            <div key={idx} className="h-9 tablet:h-[60px] ml-4">
              <PartnerInfo name={item.partner} imageUri={`images/partners/${item.logoName}`}/>
            </div>
          ))}
        </Marquee>
      </div>
      <div className="w-full tablet:hidden">
        <Marquee className="mt-20" style={{width: "100vw"}} speed={160}>
          {Partners1.map((item, idx) => (
            <div key={idx} className="h-9 tablet:h-[60px] ml-4">
              <PartnerInfo name={item.partner} imageUri={`images/partners/${item.logoName}`}/>
            </div>
          ))}
        </Marquee>
        <Marquee className="mt-8 tablet:mt-20" style={{width: "100vw"}} speed={180}>
          {Partners2.map((item, idx) => (
            <div key={idx} className="h-9 tablet:h-[60px] ml-4">
              <PartnerInfo name={item.partner} imageUri={`images/partners/${item.logoName}`}/>
            </div>
          ))}
        </Marquee>
      </div>
    </div>
  )
}
