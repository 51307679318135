import React, {UIEvent, useEffect, useRef, useState} from 'react';
import Loading from "./components/Loading";
import Section1 from "./components/Section1";
import { gsap } from "gsap";
import classNames from "classnames";
import Section2 from "./components/Section2";
import Section3 from "./components/Section3";
import Section4 from "./components/Section4";
import useIntersect from "./hooks/useIntersect";
import Footer from "./components/Footer";
import ClipIcon from "./components/ClipIcon";
import {BackToTopArrow} from "./components/ArrowIcon";
import Logo from "./components/Logo";
import {Section1Data} from "./common/constants";
import { isMobile } from 'react-device-detect';
function App() {
  const [section1Idx, setSection1Idx] = useState(0);
  const [portfolioIdx, setPortfolioIdx] = useState(0);
  const [headerColor, setHeaderColor] = useState({main: "#2C2D2F", sub: "#EB3C34"});
  const [isFirstVisit, setIsFirstVisit] = useState(localStorage.getItem('visited') === null);
  const [disableScroll, setDisableScroll] = useState(true);
  const [topReached, setTopReached] = useState(false);
  const [goingUp, setGoingUp] = useState(false);
  const [showBelowSection3, setShowBelowSection3] = useState(false);
  const [showBackToTop, setShowBackToTop] = useState(false);

  const headerRef = useRef<HTMLDivElement|null>(null);
  const footerRef = useRef<HTMLDivElement|null>(null);
  const loadingViewRef = useRef(null);
  const section1Ref = useRef<HTMLDivElement|null>(null);
  const section2Ref = useRef<HTMLDivElement|null>(null);
  const connectValueRef = useRef<HTMLDivElement|null>(null);
  const section3Ref = useRef<HTMLDivElement|null>(null);
  const section4Ref = useRef<HTMLDivElement|null>(null);
  const backToTopBtnRef = useRef<HTMLDivElement|null>(null);
  const backToTopBtnCls = classNames({
    "w-full h-full": true,
    "hidden": !showBackToTop,
  });
  const mainDivCls = classNames({
    "relative w-full h-screen font-pretendard overscroll-none": true,
    "overflow-y-hidden": disableScroll,
    "overflow-y-scroll": !disableScroll,
  });
  const [section3Entered, setSection3Entered] = useState(false);
  localStorage.removeItem('visited'); // TODO: remove on production!

  const handleCompleteLoading = () => {
    gsap.to(loadingViewRef.current, {
      duration: 1,
      opacity: 0,
      onComplete: () => {
        setIsFirstVisit(false);
        localStorage.setItem('visited', 'true');
      }
    })
  }
  function checkIfHeaderIsOverlappingDarkBg() {
    if (headerRef.current && section2Ref.current) {
      const header = headerRef.current.getBoundingClientRect();
      const sec2 = section2Ref.current.getBoundingClientRect();
      const footer = footerRef.current?.getBoundingClientRect();

      if (header.top <= sec2.top + sec2.height && header.top + header.height > sec2.top) {
        setHeaderColor({main: "#FFFFFF", sub: "#EB3C34"});
      } else if (footer && header.top <= footer.top + footer.height && header.top + header.height > footer.top) {
        setHeaderColor({main: "#FFFFFF", sub: "#EB3C34"});
      } else {
        setHeaderColor({main: "#2C2D2F", sub: "#EB3C34"});
      }
    }
  }

  function checkIfBackToTopBtnIsOverlappingFooter() {
    if (backToTopBtnRef.current) {
      if (!footerRef.current) {
        setShowBackToTop(true);
        return;
      }
      const btn = backToTopBtnRef.current.getBoundingClientRect();
      const footer = footerRef.current.getBoundingClientRect();

      if (btn.top <= footer.top + footer.height && btn.top + btn.height > footer.top) {
        setShowBackToTop(false);
      } else {
        setShowBackToTop(true);
      }
    }
  }

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    if (e.currentTarget.scrollTop === 0) {
      setTopReached(true);
      setShowBackToTop(false);
    } else {
      checkIfHeaderIsOverlappingDarkBg();
      checkIfBackToTopBtnIsOverlappingFooter();
      if (!topReached) {
        setTopReached(false);
      }
    }
  };

  const scrollToPortfolio = () => {
    if (section3Entered) {
      return;
    }
    setDisableScroll(false);
    setPortfolioIdx(0);
    section3Ref.current?.scrollIntoView({behavior: "smooth"});
  }

  const section3EntranceRef = useIntersect(async (entry, observer) => {
    if (entry === null) {
      return;
    }
    if (!section3Entered) {
      setSection3Entered(true);
      setDisableScroll(true);
      section3Ref.current?.scrollIntoView({behavior: "smooth", block: "end"});
    }
  });
  const section3EntranceRefCls = classNames({
    "absolute h-1 w-full aria-hidden:hidden": true,
    "bottom-0": !showBelowSection3,
    "top-0": showBelowSection3,
  });

  const handleBackToTop = () => {
    setGoingUp(true);
    setDisableScroll(false);
  }
  const headerClass = classNames({
    "fixed top-0 h-[80px] w-full pl-6 pr-6 tablet:pl-20 tablet:pr-20 text-[13px] flex justify-between items-center h-[35px] z-30": true,
  });

  useEffect(() => {
    setHeaderColor({main: Section1Data[section1Idx].color, sub: Section1Data[section1Idx].logoColor});
  }, [section1Idx]);

  useEffect(() => {
    if (goingUp) {
      section1Ref.current?.scrollIntoView({behavior: "smooth"});
      setTimeout(() => {
        setGoingUp(false);
      }, 500);
    }
  }, [goingUp]);

  return (
    <div className={mainDivCls} onScroll={handleScroll}>
      {isFirstVisit && (
        <div className="absolute top-0 left-0 w-full h-full overflow-hidden z-50" ref={loadingViewRef}>
          <Loading onComplete={handleCompleteLoading}/>
        </div>
      )}
      <div className="flex w-full" ref={section1Ref}>
        <Section1 loaded={!isFirstVisit}
                  currentIdx={section1Idx}
                  setCurrentIdx={setSection1Idx}
                  topReached={topReached}
                  disableScroll={disableScroll}
                  setDisableScroll={setDisableScroll}/>
      </div>
      <div className="flex flex-col w-full tablet:pb-0">
        <div className="w-full flex flex-col tablet:flex-row p-6 tablet:p-20 text-[18px] laptop:text-[28px] items-center">
          <div className="w-full tablet:w-1/3">
            UXID understands users' thoughts and behaviors, and connecting the flow of diverse experiences in everyday life.
            UXID tries to provided valuable changes to enhance our quality of life.
          </div>
          <div className="hidden tablet:flex w-1/3 h-full items-center justify-center gap-x-4">
            <div className="block h-[40px] w-[1px] tablet:w-[40%] tablet:h-[1px] bg-gray"></div>
            <div className=""><ClipIcon color="mixed" size="medium"/></div>
            <div className="block h-[40px] w-[1px] tablet:w-[40%] tablet:h-[1px] bg-gray"></div>
          </div>
          <div className="tablet:hidden w-full flex justify-start mt-7 mb-7">
            <div className="flex flex-col items-center justify-center gap-y-2">
              <div className="block h-[40px] w-[1px] bg-gray"></div>
              <div className=""><ClipIcon color="mixed" size="small"/></div>
              <div className="block h-[40px] w-[1px] bg-gray"></div>
            </div>
          </div>
          <div className="w-full tablet:w-1/3 mb-14 tablet:mb-0">
            유씨드는 고객의 생각과 행동을 이해하고,
            일상 속 다양한 경험의 흐름을 연결하여
            삶의 가치와 질을 높이는 변화를 제공합니다.
          </div>
        </div>
        <div className="w-full" ref={section2Ref}>
          <Section2 />
        </div>
      </div>
      {!goingUp && (
        <div className="flex flex-col w-full relative bg-white">
          <div className="flex flex-col mt-8 ml-4 tablet:ml-20 tablet:mt-20 text-black" ref={connectValueRef}>
            <div className="w-full flex flex-col">
              <ClipIcon color="red" size="small"/>
              <div className="mt-4 text-[13px] tablet:text-[15px] flex flex-col">
                <div>Connect</div>
                <div>Value.</div>
              </div>
            </div>
          </div>
          <div className="flex w-full relative" ref={section3Ref}>
            <div className={section3EntranceRefCls}
                 ref={section3EntranceRef}>
            </div>
            <Section3 currentIdx={portfolioIdx}
                      setCurrentIdx={setPortfolioIdx}
                      enteredScreen={section3Entered}
                      setShowIntersects={() => {
                        setShowBelowSection3(true);
                      }}
                      onEscapePrev={() => {
                        connectValueRef.current?.scrollIntoView({behavior: "smooth", block: "start"});
                        setTimeout(() => {
                          setSection3Entered(false);
                          setShowBelowSection3(false);
                          setDisableScroll(false);
                        }, 200);
                      }}
                      onEscapeNext={() => {
                        section4Ref.current?.scrollIntoView({behavior: "smooth", block: isMobile ? "end": "start"});
                        setTimeout(() => {
                          setSection3Entered(false);
                          setDisableScroll(false);
                        }, 200);
                      }} />
          </div>
        </div>
      )}
      <div className="flex w-full">
        {showBelowSection3 && (
          <div className="flex flex-col w-full">
            <div className="flex flex-col w-full p-4 tablet:p-20 text-black" ref={section4Ref}>
              <div className="w-full flex flex-col">
                <ClipIcon color="gray" size="small"/>
                <div className="mt-4 text-[13px] tablet:text-[15px] flex flex-col">
                  <div>Connect</div>
                  <div>Clients.</div>
                </div>
              </div>
            </div>
            <Section4 />
            <div className="" ref={footerRef}>
              <Footer onBackToTop={handleBackToTop}/>
            </div>
          </div>
        )}
      </div>
      <div className={headerClass} ref={headerRef}>
        <Logo mainColor={headerColor.main} subColor={headerColor.sub} />
        <div className="hidden tablet:block" style={{color: headerColor.main}}>UXID STUDIO © since 2015</div>
        <button className="w-[81px] h-[32px] border rounded-full text-base"
                style={{color: headerColor.main, borderColor: headerColor.main}}
                onClick={scrollToPortfolio}>
          Portfolio
        </button>
      </div>
      <div className="fixed bottom-4 right-2 tablet:bottom-10 tablet:right-10 z-20 w-[50px] h-[50px] flex justify-center items-center" ref={backToTopBtnRef}>
        <button className={backToTopBtnCls} onClick={handleBackToTop}>
          <BackToTopArrow color="light"/>
        </button>
      </div>
    </div>
  );
}

export default App;
