import {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import { gsap } from "gsap";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import Logo from "./Logo";
import {Section1Data} from "../common/constants";
import Section1Background from "./Section1Background";
import classNames from "classnames";
interface Props {
  loaded: boolean;
  topReached: boolean;
  disableScroll: boolean;
  setDisableScroll: Dispatch<SetStateAction<boolean>>;
  currentIdx: number;
  setCurrentIdx: Dispatch<SetStateAction<number>>;
}

export default function Section1({currentIdx, setCurrentIdx, loaded, topReached, disableScroll, setDisableScroll}: Props) {
  const [initial, setInitial] = useState(true);
  const [breadcrumb, setBreadcrumb] = useState("");
  const bgRef = useRef<HTMLDivElement|null>(null);
  const textContentsRef = useRef<HTMLDivElement|null>(null);
  const breadcrumbContentsRef = useRef<HTMLDivElement|null>(null);

  const handleScrollUp = (event: WheelEvent) => {
    if (!disableScroll && !topReached) {
      return;
    }
    setCurrentIdx(prev => Math.max(prev - 1, 0));
  };

  const handleScrollDown = (event: WheelEvent) => {
    if (!disableScroll) {
      return;
    }
    if (disableScroll && currentIdx === Section1Data.length - 1) {
      setDisableScroll(false);
      return;
    }
    setCurrentIdx(prev => Math.min(prev + 1, Section1Data.length-1));
  }

  useEffect(() => {
    if (loaded) {
      if (currentIdx < Section1Data.length - 1) {
        setDisableScroll(true);
      } else {
        setDisableScroll(false);
      }
      if (initial) {
        setInitial(false);
        return;
      }
      gsap.to(bgRef.current, {
        duration: 0.6,
        transform: `translateY(-${currentIdx * 100}vh)`,
      });

      gsap.to(textContentsRef.current, {
        duration: 0.5,
        transform: `translateY(-${currentIdx * textContentsRef.current!.offsetHeight}px)`,
      });

      const tlBreadcrumb = gsap.timeline();
      tlBreadcrumb.to(breadcrumbContentsRef.current, {
        duration: 0.4,
        opacity: 0,
        onComplete: () => {
          setBreadcrumb(Section1Data.slice(0, currentIdx).map((item, idx) => {
            return (idx === 0 ? "" : " • ") + item.title;
          }).join(""));
        }
      });
      tlBreadcrumb.to(breadcrumbContentsRef.current, {
        duration: 0.6,
        opacity: 1,
        color: Section1Data[currentIdx].color,
      });
    }
  }, [loaded, currentIdx]);

  return (
    <div className="flex flex-col h-screen w-full">
      <ReactScrollWheelHandler
        className="flex flex-col h-full w-full"
        upHandler={handleScrollUp}
        downHandler={handleScrollDown}>
        <div className="relative flex flex-col w-full h-full flex-1 text-[58px] tablet:text-[120px] laptop:text-[210px] items-center justify-center overflow-hidden">
          <div ref={bgRef} className="block w-full h-full bg-transparent">
            {Section1Data.map((item, idx) => (
              <div className="w-full h-[100vh]"
                   style={{backgroundColor: item.bgColor}}>
              </div>
            ))}
          </div>
          <div className="absolute w-full h-full overflow-hidden flex items-center justify-center">
            <Section1Background currentIdx={currentIdx} />
          </div>
          <div className="absolute w-full h-[80px] tablet:h-[250px] z-10 items-center justify-center overflow-hidden">
            <div ref={textContentsRef} className="block w-full h-full bg-transparent">
              {Section1Data.map((item, idx) => (
                <div className="flex items-center justify-center w-full h-full"
                     style={{color: item.color}}>
                  {item.title}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="absolute hidden tablet:flex bottom-16 text-[20px] w-full justify-center items-center bg-transparent"
             ref={breadcrumbContentsRef}>
          {breadcrumb}
        </div>
      </ReactScrollWheelHandler>
    </div>
  )
}
