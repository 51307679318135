import {useEffect, useRef, useState} from "react";
import { gsap } from "gsap";
interface Props {
  imageUris: string[];
  interval?: number;
  widthCls?: string;
  heightCls?: string;
  infinite?: boolean;
}

export default function SpriteImage(
  {widthCls="w-[62px]", heightCls="h-[80px]", imageUris, interval = 0.7, infinite = true}: Props) {
  const imageContentsRefs = useRef<HTMLImageElement[]>([]);
  const animate = () => {
    const tl = gsap.timeline();
    imageUris.forEach((uri, idx) => {
      tl.to(imageContentsRefs.current[idx], {
        duration: interval,
        opacity: 1,
      });
    });
    tl.to(imageContentsRefs.current, {
      duration: interval,
      opacity: 0,
      onComplete: () => {
        if (infinite) {
          animate();
        }
      }
    });
  }

  useEffect(() => {
    animate();
  }, []);

  return (
    <div className={`${widthCls} ${heightCls} block relative z-50`}>
      {imageUris.map((uri, idx) => {
        return (
          <img
            key={idx}
            className={`absolute top-0 left-0 w-full h-full opacity-0 object-contain`}
            src={uri}
            ref={el => {
              if (!el) {
                return;
              }
              imageContentsRefs.current[idx] = el;
            }}
          />
        )
      })}
    </div>
  )
}
