import ClipIcon from "./ClipIcon";
import PortfolioContents from "./PortfolioContents";
import {Section3Data} from "../common/constants";
import React, {useEffect, useRef, useState} from "react";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import {gsap} from "gsap";

interface Props {
  currentIdx: number;
  setCurrentIdx: React.Dispatch<React.SetStateAction<number>>;
  enteredScreen: boolean;
  setShowIntersects: () => void;
  onEscapePrev: () => void;
  onEscapeNext: () => void;
}

export default function Section3({currentIdx, setCurrentIdx, enteredScreen, onEscapePrev, onEscapeNext, setShowIntersects}: Props) {

  const bgRef = useRef<HTMLDivElement|null>(null);
  const portfolioRefs = React.useRef<HTMLDivElement[]>([]);
  const handleScrollUp = (event: WheelEvent) => {
    if (!enteredScreen) {
      return;
    }
    if (currentIdx > 0) {
      setCurrentIdx(prev => Math.max(prev - 1, 0));
      setShowIntersects();
    } else {
      onEscapePrev();
    }
  };

  const handleScrollDown = (event: WheelEvent) => {
    if (!enteredScreen) {
      return;
    }
    if (currentIdx < Section3Data.length - 1) {
      setCurrentIdx(prev => Math.min(prev + 1, Section3Data.length - 1));
      setShowIntersects();
    } else {
      onEscapeNext();
    }
  }

  useEffect(() => {
    if (enteredScreen) {
      gsap.to(bgRef.current, {
        duration: 0.6,
        transform: `translateY(-${currentIdx * 100}vh)`,
      });
    } else {
      gsap.to(bgRef.current, {
        duration: 0,
        transform: `translateY(0px)`,
      });
    }
  }, [currentIdx]);

  return (
    <div className="relative w-full h-screen block">
      <ReactScrollWheelHandler
        className="w-full h-full overflow-hidden"
        preventScroll={enteredScreen}
        upHandler={handleScrollUp}
        downHandler={handleScrollDown}>
        <div className="block w-full h-full bg-transparent" ref={bgRef}>
          {Section3Data.map((data, index) => (
            <div className="flex w-full h-[100vh] tablet:pt-0"
                 key={index}
                 ref={el => {
                   if (!el) return;
                   return portfolioRefs.current[index] = el;
                 }}>
              <PortfolioContents title={data.title}
                                 indexString={data.indexString}
                                 description={data.description}
                                 imageNames={data.imageNames}
                                 projects={data.projects}
                                 direction={data.direction as 'left' | 'right'} />
            </div>
          ))}
        </div>
      </ReactScrollWheelHandler>
    </div>
  )
}
