import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import {useEffect} from "react";
interface Props {
  title: string;
  indexString: string;
  description: string;
  imageNames: string[];
  projects: string[];
  direction: "left" | "right";
}

function TextContainer({title, indexString, description, projects, direction} :
                         {title: string, indexString: string, description: string, projects: string[], direction: string}) {
  return (
    <div className={`${direction === 'left' ? "items-start" : "items-end text-right"} w-full laptop:w-1/2 laptop:aspect-[5/4] flex flex-col laptop:justify-end`}>
      <div className="text-red font-bold text-[14px] tablet:text-[18px]">{indexString}</div>
      <div className="text-black font-bold text-[24px] tablet:text-[40px] mt-1 tablet:mt-6 tablet:mb-28 mb-20">{title}</div>
      {/*<div className="text-black text-[14px] tablet:text-[18px] mt-3 tablet:mt-10 break-keep">{description}</div>*/}
      <div className="flex flex-col mt-[16px] text-[13px] tablet:text-[16px] gap-y-1 tablet:gap-y-[10px]">
        {projects.map((project, index) => (
          <div key={index}
               className={`${direction === 'left' ? "justify-start" : "justify-end text-right"} flex items-center`}>
            <div className="w-[5px] h-[5px] rounded-full bg-red mr-3"/>
            <div>{project}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

function ImageSlider({imageNames} : {imageNames: string[]}) {
  return (
    <div className="mt-7 tablet:mt-0 w-full laptop:w-1/2">
      <Carousel className="w-full"
                autoPlay
                interval={2000}
                swipeable={false}
                infiniteLoop
                showThumbs={false}
                showIndicators={false}
                showArrows={false}
                showStatus={false}>
        {imageNames.map((name, index) => (
          <div key={index} className="w-full aspect-[6/4] tablet:aspect-[5/4]">
            <img src={`/images/portfolio/${name}`} alt={`img-${index}`} className="h-full object-cover"/>
          </div>
        ))}
      </Carousel>
    </div>
  )
}
export default function PortfolioContents({title, indexString, description, imageNames, projects, direction}: Props) {
  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="tablet:hidden w-full flex flex-col items-center justify-center pl-4 pr-4">
        <TextContainer title={title} indexString={indexString} description={description} projects={projects} direction="left"/>
        <ImageSlider imageNames={imageNames}/>
      </div>
      <div className="hidden tablet:flex tablet:items-center laptop:items-start w-full max-w-[1400px] gap-x-[30px] pb-[70px] pt-[70px] pl-20 pr-20">
        {direction === "left" ? (
          <>
            <TextContainer title={title} indexString={indexString} description={description} projects={projects} direction="left"/>
            <ImageSlider imageNames={imageNames}/>
          </>
        ) : (
          <>
            <ImageSlider imageNames={imageNames}/>
            <TextContainer title={title} indexString={indexString} description={description} projects={projects} direction="right"/>
          </>
        )}
      </div>
    </div>
  )
}
